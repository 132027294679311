.block {
  width: 90vw;
  height: 100vh;
  position: relative;
  margin-left: 5vw;
  margin: 0 auto;
  p {
    font-family: "Oxanium", sans-serif;
    text-align: start;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    color: white;
  }
}
.block1 {
  display: flex;
  height: 50vh;
  margin-top: auto;
  div {
    display: none;
  }
  img {
    position: absolute;
    bottom: 0;
    left: 10vw;
    width: 401px;
    height: auto;
    display: block;
  }
  p {
    margin-top: auto;
    margin-left: auto;
    max-width: 560px;
    width: auto;
  }
}
.block2 {
  display: flex;
  margin-left: 5vw;
  div {
    text-align: end;
    .imgBlock {
      position: relative;
      display: block;
      width: min-content;
      height: min-content;
      margin-left: auto;
      z-index: 1;
      .img3,
      .img4 {
        display: none;
      }
      img {
        //   position: relative;
        display: block; /* Убираем пустые пространства вокруг изображения */
        margin-left: auto;
        z-index: 1;
      }

      &::before {
        content: "";
        width: 10px;
        height: 50px;
        box-shadow: 0 0 40px 10px rgb(255, 255, 255); /* Пример тени */
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        z-index: -1;
      }
      &::after {
        content: "";
        width: 50px;
        height: 10px;
        box-shadow: 0 0 40px 10px rgb(255, 255, 255); /* Пример тени */
        position: absolute;
        left: 50%;
        top: 25px;
        transform: translateX(-50%);
        background-color: white;
        z-index: -1;
      }
    }
    p {
      text-align: start;
      width: 480px;
    }
  }
  img {
    height: min-content;
    width: auto;
  }
  .img3 {
    margin-top: 5rem;
    height: min-content;
    width: auto;
  }
  .img4 {
    margin-bottom: 100%;
    width: auto;
  }
}
.block3 {
  display: flex;
  margin-left: 5vw;
  align-items: center;
  padding-bottom: 50px;
  gap: 70px;
  div {
    text-align: end;
    .imgBlock2 {
      display: none;
    }
    .imgBlock {
      position: relative;
      width: min-content;
      margin-left: auto;
      &::after {
        content: "";
        width: 50%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 40px 10px rgb(255, 255, 255); /* Пример тени */
        left: 25px;
        top: 25px;
        z-index: -1;
      }
      img {
        width: 280px;
        height: auto;
      }
      .img6,
      .img7 {
        display: none;
      }
    }
    p {
      max-width: 480px;
      width: auto;
      text-align: start;
    }
  }
  .img6 {
    width: auto;
    height: 500px;
  }
  .img7 {
    width: auto;
    height: 290px;
    margin-bottom: auto;
  }
}
.block4 {
  margin-left: 5vw;
  position: relative;
  display: flex;
  .content {
    width: 388px;
    height: auto;
    position: absolute;
    z-index: 1;
    .title {
      text-align: start;
      p {
        font-size: 13px;
        font-weight: 13px;
        margin: 0;
        margin-bottom: 10px;
      }
      h1 {
        margin: 0;
        font-weight: 700px;
        font-size: 90px;
        line-height: 93px;
        color: rgba(255, 86, 67, 1);
        span {
          font-weight: 300;
          color: white;
        }
      }
    }
    .description {
      text-align: start;
      p {
        font-weight: 300;
        font-size: 45px;
        line-height: 59px;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Количество строк, после которых текст будет обрезан */
        -webkit-box-orient: vertical;
      }
      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: rgba(255, 86, 67, 1);
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .blockLeft {
    display: flex;
    flex-direction: column;
    align-items: end;
    position: relative;
    width: 700px;
    margin-top: -20px;
    .blockimg1 {
      width: min-content;
      position: relative;
      .img8 {
        width: 280px;
        height: auto;
      }

      &::after {
        content: "";
        width: 50%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 40px 10px rgb(255, 255, 255); /* Пример тени */
        left: 50%;
        transform: translateX(-50%);
        top: 25px;
        z-index: -1;
      }
    }
    .blockimg2 {
      width: min-content;
      position: relative;
      .img10 {
        margin-right: -30px;
        width: 410px;
        height: auto;
      }

      &::after {
        content: "";
        width: 30%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
        left: 35%;
        transform: translateX(-50%);
        top: 45px;
        z-index: -1;
      }
      &::before {
        content: "";
        width: 30%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
        right: 20%;
        transform: translateX(50%);
        top: 45px;
        z-index: -1;
      }
    }
  }
  .blockRight {
    margin-top: 20px;
    width: 50%;
    .blockimg3 {
      margin-left: 40%;
      .img9 {
        width: 200px;
        height: auto;
      }
    }
    .blockimg4 {
      width: min-content;
      margin-left: auto;
      position: relative;
      .img11 {
        width: 500px;
        height: auto;
        margin-right: 30px;
      }
      &::before {
        content: "";
        width: 20%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
        left: 30%;
        transform: translateX(-50%);
        top: 35px;
        z-index: -1;
      }
      &::after {
        content: "";
        width: 40%;
        height: 100px;
        position: absolute;
        box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
        right: 45%;
        transform: translateX(50%);
        top: 35px;
        z-index: -1;
      }
      img {
        width: auto;
      }
    }
  }
}
@media (max-width: 1310px) {
  .block2 {
    .img3 {
      width: 300px;
      height: auto;
    }
    .img4 {
      width: 200px;
      height: auto;
    }
  }
}
@media (max-width: 1107px) {
  .block1 {
    display: block;
    text-align: start;
    div {
      height: auto;
      display: flex;
      margin-bottom: 20px;
      text-align: center;

      img {
        display: none;
      }
      .img1 {
        display: block;
        width: 209px;
        height: 340px;
        margin-top: -8rem;
      }
      .img2 {
        display: block;
        width: 180px;
        height: auto;
      }
    }
    img {
      display: none;
      position: relative;
    }
    p {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }
  .block2 {
    width: 100%;
    height: 80vh;
    .img3,
    .img4 {
      display: none;
    }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1px;
      .imgBlock {
        width: 100%;
        display: flex !important;
        position: relative;
        img {
          display: none;
        }
        .img3,
        .img4 {
          display: flex;
          margin: 0;
          position: relative;
        }
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }

      p {
        width: 80%;
        height: min-content;
      }
    }
  }
  .block3 {
    padding: 0;
    div {
      width: 100%;
      margin: auto 0;
      display: flex;
      flex-wrap: wrap;
      p {
        max-width: 100%;
        width: 100%;
        height: min-content;
      }
      .imgBlock {
        display: none;
      }
      .imgBlock2 {
        display: flex;
        text-align: center;
        // flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        div {
          display: flex;
          justify-content: center;
          width: min-content;
          .img5 {
            height: 220px;
            width: auto;
          }
          .img7 {
            height: 170px;
            width: auto;
          }
        }
        .img6 {
          height: 249px;
        }
        .img6,
        .img7 {
          display: block;
        }
      }
    }
    .img6,
    .img7 {
      display: none;
    }
  }
  .block4 {
    flex-wrap: wrap;
    .content {
      position: relative;
      width: 100%;
      .title {
        h1 {
          font-size: 40px;
        }
      }
      .description {
        p {
          font-size: 32px;
          font-weight: 300;
          line-height: 32px;
        }
      }
    }
    .blockLeft {
      width: 49%;
      text-align: center;
      .blockimg1 {
        margin: 0 auto;
        .img8 {
          width: 180px;
        }
      }
      .blockimg2 {
        margin: 0 auto;
        margin-right: 30%;
        .img10 {
          width: 380px;
        }
      }
    }
    .blockRight {
      width: 49%;
      margin: 0;
      .blockimg3 {
        .img9 {
          width: 180px;
        }
      }
      .blockimg4 {
        margin: 0 auto;
        .img11 {
          width: 300px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .block {
    height: 100vh;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .block1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    div {
      height: auto;
      margin-top: auto;
      .img1 {
        width: 109px;
        height: 171px;
      }
      .img2 {
        width: 154px;
        height: 243px;
      }
    }
  }
  .block2 {
    height: 80vh;
    div {
      .imgBlock {
        height: 60%;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        .img3 {
          width: 270px;
          margin-top: auto;
        }
        .img4 {
          width: 250px;
          margin-bottom: auto;
        }
      }
    }
  }
  .block3 {
    height: 80vh;
    div {
      .imgBlock2 {
        display: flex;
        div {
          display: flex;
          width: 100%;
          justify-content: space-around;
          flex-wrap: nowrap;
          .img7 {
            margin: 0;
            margin-top: auto;
          }
        }
      }
    }
  }
  .block4 {
    height: 50vh;
    .content {
      .title {
        p {
          margin-bottom: 5px;
        }
        h1 {
          font-weight: 500;
          font-size: 34px;
          line-height: 40px;
        }
      }
      .description {
        p {
          font-weight: 300;
          font-size: 20px;
          line-height: 28px;
        }
        a {
          font-size: 13px;
        }
      }
    }
    .blockLeft {
      .blockimg1 {
        .img8 {
          width: 134px;
        }
      }
      .blockimg2 {
        margin-top: 30px;
        .img10 {
          width: 208px;
          margin: 0;
          margin-left: 5%;
        }
        &::after {
          content: "";
          width: 20%;
          height: 80px;
        }
        &::before {
          content: "";
          width: 20%;
          height: 80px;
        }
      }
    }
    .blockRight {
      .blockimg3 {
        .img9 {
          width: 120px;
          height: 153px;
        }
      }
      .blockimg4 {
        margin-top: 30px;
        .img11 {
          width: 192px;
          height: 92px;
        }
        &::after {
          content: "";
          width: 30%;
          height: 10px;
          position: absolute;
          box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
          left: 35%;
          transform: translateX(-50%);
          top: 5px;
          z-index: -1;
        }
        &::before {
          content: "";
          width: 30%;
          height: 10px;
          position: absolute;
          box-shadow: 0 0 70px 0px rgb(255, 255, 255); /* Пример тени */
          right: 20%;
          transform: translateX(50%);
          top: 5px;
          z-index: -1;
        }
      }
    }
  }
}
@media (max-width: 577px) {
  .block {
    width: 90% !important;
    margin-left: auto;
    margin-right: 0;
  }
  .block1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    div {
      height: auto;
      margin-top: auto;
      .img1 {
        width: 109px;
        height: 171px;
      }
      .img2 {
        width: 100px;
        height: auto;
      }
    }
  }
  .block2 {
    div {
      .imgBlock {
        .img3 {
          width: 170px;
        }
        .img4 {
          width: 160px;
          margin-right: 30px;
        }
      }
    }
  }
  .block4 {
    .blockLeft {
      text-align: center;
      justify-content: center;
      align-items: center;
      .blockimg2 {
        margin: 0;
        // margin-right: 100%;
        .img10 {
          margin: 0;
        }
        &::before{
          width: 15px;
        }
      }
    }
    .blockRight{
      text-align: center;
      .blockimg3{
        margin: 0;
        margin-left: 10px;
      }
      .blockimg4{
        margin: 0;
        margin-top: 30px;
        margin-right: 10px;
      }
    }
  }
}
