.root{
    font-family: "Oxanium", sans-serif;
    padding-top: 20px;
    height: auto;
    max-width: 1500px;
    margin:  0 auto;  
    width: 90vw;
    overflow: auto;
    margin-bottom: auto;
    &.ru{        
        font-family: "Montserrat", sans-serif !important;
    }
    // .model{
    //   position: absolute;
    //   top: -15%;
    //   right: 0%;
    //   transform: translateY(40%);
    //   width:  600px;
    //   height: 110vh;
    //   z-index: 90;
    // }
    .content{
        margin-left: 5vw;
        color: white;
        max-width: 530px;
        text-align: start;
        .title{
            p{
                font-size: 13px;
                font-weight: 13px;
                margin: 0;
                margin-bottom: 10px;
            }
            h1{
                margin: 0;
                font-weight: 700px;
                font-size: 90px;
                line-height: 93px;
                color: rgba(255, 86, 67, 1);
                span{
                    font-weight: 300;
                    color: white;
                }
            }
        }
        .description{
            p{
                font-weight: 300;
                font-size: 45px;
                line-height: 59px;
                margin: 0;
            }
            a{
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: rgba(255, 86, 67, 1);
                margin-top: 10px;
            }
        }
    }
}
@media (min-width: 1400px) {
    .root{
        .model{
            width: 50%;
        }
    }
}
@media (max-width: 1200px) {
    .root{
        .model{
            width: 500px;
            height: 70vh;
            top: 17%;
            bottom: 0;
        }
    }
}
@media (max-width: 768px) {
    .root{
        margin-top: 50px;
        .model{
            display: none;
        }
        .content{
            .title{
                p{
                    margin-bottom: 5px;
                }
                h1{
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 40px;
                }
            }
            .description{
                p{
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 28px;
                }
                a{
                    font-size: 13px;
                }
            }
        }
    }
}