.root {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 10px 50px;
  max-width: 1500px;
  margin: 0 auto;
  transition: 0.3s ease; /* Анимация для плавного появления/исчезания */
  z-index: 10;
}

.scrolledDown {
  bottom: 0;
  left: 0;
  transform: translateY(0);
  z-index: 10;
}
.scrolledTop {
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  z-index: 10;
}

.network {
  display: flex;
  align-items: center;
  gap: 40px;
  z-index: 10;
}
.saundBlock {
  display: flex;
  gap: 3px;
  align-items: center;
  position: relative;
  width: 26px;
  height: 20px;
  cursor: pointer;
  .line {
    width: 1px;
    height: auto;
    border: 1px solid white;
    border-radius: 500px;
    position: absolute;
    transition: all 0.3s ease; // добавлена анимация для плавного перехода
  }

  .line1 {
    height: 7px;
    left: 0;
  }

  .line2 {
    height: 9px;
    left: 4px;
  }

  .line3 {
    height: 14px;
    left: 8px;
  }

  .line4 {
    height: 19px;
    left: 12px;
  }

  .line5 {
    height: 9px;
    left: 16px;
  }

  .line6 {
    height: 14px;
    left: 20px;
  }

  &.saund {
    .line1,
    .line6 {
      height: 20px;
      opacity: 1;
      bottom: 0;
    }

    .line1 {
      transform: rotate(45deg) translateX(-0%);
      left: 50%;
    }

    .line6 {
      transform: rotate(-45deg) translateX(-0%);
      left: 50%;
    }

    .line:not(.line1):not(.line6) {
      opacity: 0;
    }
  }
}
