.root {
  padding-top: 20px;
  max-width: 1500px;
  width: max-content;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  width: 80vw;
  height: 100vh;
  &.ru {
    .content {
      .title {
        font-family: "Montserrat", sans-serif !important;
        h1 {
          font-size: 40px;
        }
      }
      .blocks_description {
        font-family: "Montserrat", sans-serif !important;
        .flexBlock {
          gap: 10px;
          .block {
            height: 140px;
            .description {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .content {
    text-align: start;
    color: white;
    padding-right: 0rem;
    position: relative;
    .model {
      position: absolute;
      top: -8%;
      right: 50%;
      transform: translateX(40%);
      width: 400px;
      height: 100vh;
      z-index: 1;
    }
    .title {
      margin-bottom: 20px;
      font-family: "Oxanium", sans-serif;
      p {
        font-weight: 500;
        font-size: 13px;
        margin: 0;
      }
      h1 {
        max-width: 351px;
        margin: 0;
        margin-top: 10px;
        font-weight: 700;
        line-height: 47px;
        font-size: 45px;
        color: rgba(255, 73, 72, 1);
        text-transform: uppercase;
        z-index: 3;
        span {
          font-weight: 300;
          color: white;
          z-index: 3;
        }
      }
    }
    .blocks_description {
      display: flex;
      justify-content: space-between;
      .flexBlock {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .block {
          width: 325px;
          height: 154px;
          z-index: 3;
          .title {
            font-size: 18px;
            font-weight: 500;
            margin: 0;
          }
          .description {
            font-size: 16px;
            font-weight: 300px;
            margin: 0;
            color: rgba(255, 255, 255, 0.5);
            display: -webkit-box;
            -webkit-line-clamp: 6; /* Количество строк, после которых текст будет обрезан */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .block3 {
          margin-left: 8rem;
        }
        .block4 {
          height: 184px;
          overflow: hidden;
          text-overflow: ellipsis;
          .description {
            display: -webkit-box;
            -webkit-line-clamp: 8; /* Количество строк, после которых текст будет обрезан */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .block5 {
          margin-left: -8rem !important;
          .description {
            font-size: 19px;
          }
        }
        .block6 {
          margin-left: -20rem;
        }
      }
      .flexBlock1 {
        margin-top: 10px;
        position: relative;
        .block7 {
          position: absolute;
          top: -10rem;
          margin-left: -8rem;
        }
      }
    }
    .mobileBlock {
      display: none;
      a {
        width: fit-content;
        display: flex;
        align-items: center;
      }
    }
  }
}
@media (min-width: 2265px) {
  .root,
  .ru {
    .content {
      margin-left: 10vw !important;
    }
  }
  .root{
    .content {
      margin-left: 10vw !important;
    }
  }
}
@media (max-width: 1365px) {
  .root,
  .ru {
    .content {
      .blocks_description {
        .flexBlock {
          .block3 {
            margin-left: 4rem;
          }
        }
      }
    }
  }
  .root{.content {
    .blocks_description {
      .flexBlock {
        .block3 {
          margin-left: 4rem;
        }
      }
    }
  }}
}
@media (max-width: 1200px) {
  .root,
  .ru {
    .content {
      .blocks_description {
        .flexBlock {
          .block3 {
            margin-left: 4rem;
          }
        }
      }
    }
  }
  .root{
    .content {
      .blocks_description {
        .flexBlock {
          .block3 {
            margin-left: 4rem;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .root,
  .ru {
    .content {
      .blocks_description {
        .flexBlock {
          .block5 {
            margin-left: -1rem !important;
          }
          .block6 {
            margin-left: -2rem;
          }
          .block3 {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .root {
    .content {
      .blocks_description {
        .flexBlock {
          .block5 {
            margin-left: -1rem !important;
          }
          .block6 {
            margin-left: -2rem;
          }
          .block3 {
            margin-left: 2rem;
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .root,
  .ru {
    .content {
      .blocks_description {
        .flexBlock1 {
          .block7 {
            margin-left: 0rem;
          }
        }
      }

      .model {
        right: 10vw;
      }
    }
  }
  .root {
    .content {
      .blocks_description {
        .flexBlock1 {
          .block7 {
            margin-left: 0rem;
          }
        }
      }

      .model {
        right: 10vw;
      }
    }
  }
}
@media (max-width: 910px) {
  .root,
  .ru {
    .content {
      .blocks_description {
        .flexBlock {
          .block5 {
            margin-left: 0rem !important;
          }
          .block6 {
            margin-left: 0rem;
          }
          .block3 {
            margin-left: 0rem;
          }
        }
      }
    }
  }
  .root{
    .content {
      .blocks_description {
        .flexBlock {
          .block5 {
            margin-left: 0rem !important;
          }
          .block6 {
            margin-left: 0rem;
          }
          .block3 {
            margin-left: 0rem;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .root {
    &.ru {
      .content {
        padding-right: 10px;
        .model {
          bottom: 0;
          top: 65%;
          height: 50vh;
          right: 15vw;
          z-index: 90;
        }
        .title {
          p {
            display: none;
          }
          h1 {
            font-weight: 300;
            font-size: 34px;
            width: auto;
            text-wrap: wrap;
          }
        }
        .description {
          display: none;
        }
        .blocks_description {
          flex-direction: column;
          margin-top: 10px;
          .flexBlock {
            gap: 10px;
            .block {
              height: auto !important;
              width: 100%;
              .title {
                font-weight: 300;
                font-size: 20px;
              }
              .description {
                display: none;
              }
            }
            .block5 {
              margin: 0;
            }
          }
          .flexBlock1 {
            margin-top: 10px;
            position: relative;
            .block7 {
              position: relative;
              top: 0;
              margin-left: 0rem;
            }
          }
        }
        .mobileBlock {
          display: block;
          margin-top: 10px;
          width: 100%;
          a {
            text-decoration: none;
            color: rgba(255, 73, 72, 1);
            font-size: 15px;
            font-weight: 500;
            line-height: 22px;
            gap: 10px;
            display: inline-flex;
            width: 500px;
            img {
              width: 15px;
              font-weight: 900;
            }
          }
        }
      }
    }
    .content {
      padding-right: 10px;
      .model {
        bottom: 0;
        top: 65%;
        height: 50vh;
        right: 15vw;
        z-index: 90;
      }
      .title {
        p {
          display: none;
        }
        h1 {
          font-weight: 300;
          font-size: 34px;
          width: auto;
          text-wrap: wrap;
        }
      }
      .description {
        display: none;
      }
      .blocks_description {
        flex-direction: column;
        margin-top: 10px;
        .flexBlock {
          gap: 10px;
          .block {
            height: auto !important;
            width: 100%;
            .title {
              font-weight: 300;
              font-size: 20px;
            }
            .description {
              display: none;
            }
          }
          .block5 {
            margin: 0;
          }
        }
        .flexBlock1 {
          margin-top: 10px;
          position: relative;
          .block7 {
            position: relative;
            top: 0;
            margin-left: 0rem;
          }
        }
      }
      .mobileBlock {
        display: block;
        margin-top: 10px;
        width: 100%;
        a {
          text-decoration: none;
          color: rgba(255, 73, 72, 1);
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          gap: 10px;
          display: inline-flex;
          width: 500px;
          img {
            width: 15px;
            font-weight: 900;
          }
        }
      }
    }
  }
}
