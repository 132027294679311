.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px 0 50px;
  max-width: 1500px;
  margin: 0 auto;
  font-family: "Oxanium", sans-serif;
  &.ru {
    font-family: "Montserrat", sans-serif;
  }
  .logo {
    z-index: 100;
  }

  .mainMenu {
    display: flex;
    align-items: center;
    gap: 24px;
    a {
      text-decoration: none;
      color: white;
      font-size: 20px;
      &:hover {
        color: rgba(255, 73, 72, 1);
      }
    }
    .dropdown {
      position: relative;
      padding: 3px;
      &.dropdownBlack{
        background-color: rgba(0, 0, 0, 0.2);
      }
      .dropdownMainText {
        color: white;
        display: flex;
        align-items: center;
        font-size: 15px;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 15px;
        }
        
      }
      .dropdownLanguageNo{
        display: none;
      }
      .dropdownLanguage{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left:0;
        top: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
      }
      .lenguage {
        background: none;
        border: none;
        color: white;
        font-size: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        z-index: 9999;
        svg {
          width: 15px;
        }
        img {
          svg {
            path {
              fill: rgba(255, 73, 72, 1);
            }
          }
        }
        &:hover {
          color: rgba(255, 73, 72, 1);
          svg {
            path {
              fill: rgba(255, 73, 72, 1);
            }
          }
        }
      }
    }

    .burgerMenu {
      width: 26px;
      height: 19px;
      position: relative;
      cursor: pointer;
      z-index: 10000;
      .burgerLine {
        display: block;
        position: absolute;
        height: 1px;
        background: #fff;
        border: 1px solid white;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        width: 100%;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child {
          bottom: 0;
        }
      }

      &.open {
        .burgerLine {
          &:first-child {
            top: 9.5px;
            border-color: rgba(255, 73, 72, 1);
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            border-color: rgba(255, 73, 72, 1);
            top: 9.5px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: radial-gradient(#3b1f37, #130e21);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    .dropdownMenu {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 90px;
        line-height: 144px;
        font-weight: 300;
        text-decoration: none;
        color: white;
        padding: 10px;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: rgba(255, 73, 72, 1);
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .root {
    .menu {
      .dropdownMenu {
        a {
          font-size: 46px;
          line-height: 90px;
          padding: 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .root {
    padding-left: 10px;
    padding-right: 10px;
    .logo{
      display: flex;
      align-items: center;
    }
    .mainMenu{
      gap: 10px;
      a{
        font-size: 14px;
        width: min-content;
      }
      .dropdown{
        .dropdownLanguage{
          font-size: 14px;
        }
        .dropdownMainText{
          font-size: 14px;
        }
      }
    }
    .menu {
      .dropdownMenu {
        a {
          font-size: 36px;
          line-height: 58px;
        }
      }
    }
  }
}
