@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oxanium:wght@200..800&display=swap');
*{
  box-sizing: border-box;
}
.App {
  /* background-color: rgba(24, 19, 35, 1); */
  background: radial-gradient(#3b1f37, #130E21);
  width: 100%;
  height: 100vh;
  font-family: "Oxanium", sans-serif !important;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.App .ru{
  font-family: "Montserrat", sans-serif !important;
}
/* src/App.module.scss */
/* .slideContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: lightblue;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 2rem;
} */
.sectionStick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  display: flex;
  gap: 34px;
  flex-direction: column;
  transition: 0.3s ease;
  z-index: 1000;
}

.dot {
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid rgba(255, 255, 255, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot::after {
  content: "";
  width: 4.5px;
  height: 4.5px;
  background-color: rgba(217, 217, 217, 0.5);
}

.active {
  border-color: rgba(226, 99, 50, 1);
}

.active::after {
  background-color: rgba(217, 217, 217, 1);
}
@media (max-width: 1237) {
  .App{
    height: auto;
  }
}
@media (max-width: 768px) {
  .sectionStick{
    display: none;
  }
}