
.root {
  font-family: "Oxanium", sans-serif;
  padding-top: 20px;
  height: 100vh;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  &.ru {
    font-family: "Montserrat", sans-serif !important;
    .content {
      .inputBlocks {
        form {
          label {
            input {
              font-family: "Montserrat", sans-serif !important;
            }
          }
          button {
            font-family: "Montserrat", sans-serif !important;
          }
        }
      }
    }
  }
  .model {
    position: absolute;
    top: -15%;
    right: 0%;
    transform: translateY(40%);
    width: 500px;
    height: 110vh;
    z-index: 90;
  }
  .content {
    margin-left: 15vw;
    text-align: start;
    max-width: 710px;
    width: min-content;
    .text {
      p {
        font-weight: 500;
        font-size: 13px;
        color: white;
        margin: 0;
      }
      h1 {
        font-size: 40px;
        font-weight: 700px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* Количество строк, после которых текст будет обрезан */
        -webkit-box-orient: vertical;
        max-width: 418px;
        color: rgba(255, 86, 67, 1);
        margin: 10px 0 30px 0;
      }
    }
    .inputBlocks {
      form {
        display: flex;
        flex-direction: column;
        width: 710px;
        label {
          width: 100%;
          border-bottom: 1px solid rgba(128, 128, 128, 0.5);
          margin-top: 30px;
          input {
            width: 100%;
            padding: 23px 16px;
            font-weight: 400;
            font-size: 30px;
            line-height: 31px;
            border: 0;
            background-color: transparent;
            color: white;
            outline: none;
          }
        }
        button {
          cursor: pointer !important;
          margin-top: 30px;
          width: min-content;
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 34px;
          font-weight: 700;
          color: rgba(255, 86, 67, 1);
          padding: 0 16px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .root {
    .model {
      width: 500px;
      height: 70vh;
      top: 30%;
      bottom: 0;
    }
  }
}
@media (max-width: 1080px) {
  .root {
    .content {
      .inputBlocks {
        form {
          width: 500px;
          label {
            margin-top: 20px;
            input {
              padding: 16px 8px;
            }
          }
          button {
            margin-top: 20px;
            cursor: pointer !important;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .root {
    .model {
      display: none;
    }
    .content {
      max-width: none !important;
      width: 80%;
      .text {
        h1 {
          font-size: 20px;
          font-weight: 500px;
          line-height: 25px;
          max-width: none;
          width: 80%;
        }
        p {
          font-size: 13px;
          font-weight: 300;
          line-height: 16px;
        }
      }
      .inputBlocks {
        form {
          min-width: none;
          width: 100%;
          label {
            margin-top: 20px;
            input {
              font-size: 14px;
              font-weight: 400;
              line-height: 15px;
              padding: 12px 0px;
            }
          }
          button {
            margin-top: 20px;
            font-size: 18px;
            font-weight: 700px;
            line-height: 19px;
            margin-left: auto;
          }
        }
      }
    }
  }
}
