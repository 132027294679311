.swiper {
  width: 100vw;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s ease-out;
}

.swiper-slide.swiper-slide-active {
  transform: scale(1) !important;
  width: 100%;
  /* Размер активного слайда */
  min-height: max-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  transform: scale(0.8) !important;
  /* Размер неактивного слайда */
  width: 80%;
  min-height: 100vh !important;
}

.swiper-pagination {
  position: absolute;
  left: 20px !important;
  top: 50% !important;
  transform: translateY(-30%) !important;
  width: min-content !important;
  gap: 15px !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 10;

}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background-color: transparent;
  opacity: 1;
}

.swiper-pagination-bullet::after {
  display: block;
  position: absolute;
  content: "" !important;
  width: 5px !important;
  height: 5px !important;
  background-color: rgba(217, 217, 217, .5) !important;
}

.swiper-pagination-bullet-active {
  border-color: rgba(226, 99, 50, 1);
}

.swiper-pagination-bullet-active::after {
  background-color: rgb(255, 255, 255) !important;
}

.swiper-slide img {
  display: block;
  width: 14px;
  height: 14px;
  object-fit: cover;
  text-align: end;
}

.mySwiper {
  width: 100%;
  max-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.4s ease;
  position: relative !important;
}

.mySwiper.ru .sectionsContainer .section .content .text {
  font-family: "Montserrat", sans-serif !important;
}

.sectionStick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  display: flex;
  gap: 34px;
  flex-direction: column;
  transition: 0.4s ease;
  z-index: 20;
}

.sectionStick.stikNone {
  display: none !important;
  left: 90vw !important;
  right: 20px;
}

.sectionStick .dot {
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid rgba(255, 255, 255, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionStick .dot::after {
  content: "";
  width: 4.5px;
  height: 4.5px;
  background-color: rgba(217, 217, 217, 0.5);
}

.sectionStick .active {
  border-color: rgba(226, 99, 50, 1);
}

.sectionStick .active::after {
  background-color: rgba(217, 217, 217, 1);
}

.sectionsContainer .section .content {
  position: relative;
  margin-top: -1rem;
}

.sectionsContainer .section .content .picture {
  width: 820px;
  height: 475px;
  position: relative;
  box-shadow: -10px 10px 30px -1px rgba(0, 0, 0, 0.5);
}

.sectionsContainer .section .content .picture img {
  width: 100%;
  height: 100%;
}

.sectionsContainer .section .content .picture::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.06);
  position: absolute;
  bottom: -1px;
  left: -5%;
}

.sectionsContainer .section .content .picture::before {
  content: "";
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.06);
  position: absolute;
  bottom: -10%;
  left: 0%;
}

.sectionsContainer .section .content .text {
  display: flex;
  justify-content: space-around;
  align-items: start;
  padding: 0 37px;
  margin-top: 40px;
  font-family: "Oxanium", sans-serif;
}

.sectionsContainer .section .content .text .number {
  width: 50px;
}

.sectionsContainer .section .content .text .number p {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px !important;
  color: rgba(255, 255, 255, 0.2);
  margin: 0;
}

.sectionsContainer .section .content .text .description {
  width: 613px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.sectionsContainer .section .content .text .description h5 {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: start;
  margin: 4px 0;
}

.sectionsContainer .section .content .text .description p {
  margin: 0;
  text-align: start;
  font-weight: 300;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.linkSwiper a {
  text-decoration: none;
  color: rgba(233, 68, 69, 1);
  font-weight: 500;
  font-size: 17px;
  font-family: Oxanium;
  line-height: 25.19px;
  height: min-content;
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  color: white;
}

.sectionsContainer .activeSection,
.sectionsContainer .activeSection2 {
  flex: 0 0 0px;
  height: auto;
  margin: 0 auto;
  transition: 0.4s ease;
  transform: scale(1);
  padding: 50vh 0;
}

.sectionsContainer .inactiveSection,
.sectionsContainer .inactiveSection2 {
  transform: scale(0.7);
  margin: 0 auto;
  opacity: 0.5;
  transition: 0.4s ease;
}

.sectionsContainer .inactiveSection2 {
  transform: scale(0.8);
}

.content {
  padding: 20px;
}

@media (max-width: 1300px) {
  .mySwiper .model {
    top: 10% !important;
    right: -6vw !important;
    height: 50vw !important;
  }
}

@media (max-width: 920px) {
  .mySwiper .section {
    flex: 0 0 100% !important;
  }

  .mySwiper .section.flexSection {
    flex: 0 0 20px !important;
  }

  .mySwiper .section .content .picture {
    width: 640px !important;
    margin: 0 auto !important;
  }

  .mySwiper .model {
    top: 20% !important;
    right: -10vw !important;
  }
}

@media (max-width: 768px) {
  .mySwiper .model {
    display: none !important;
  }

  .mySwiper .section {
    flex: 0 0 460px !important;
  }

  .mySwiper .section.flexSection {
    flex: 0 0 0px !important;
  }

  .mySwiper .section .content .picture {
    width: 460px !important;
    height: 250px !important;
    margin: 0 auto !important;
  }

  .mySwiper .section .content .text {
    width: 460px !important;
  }

  .mySwiper .sectionStick {
    left: 10px !important;
  }

  .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
  }

  .swiper-pagination-bullet::after {
    width: 3px !important;
    height: 3px !important;
  }

  .swiper-pagination {
    left: 10px !important;
  }

  .mySwiper .sectionsContainer .inactiveSection {
    transform: scale(0.8) !important;
    margin: 0 auto !important;
    opacity: 0.5 !important;
    transition: 0.4s ease !important;
  }
}

@media (max-width: 545px) {
  .mySwiper .section {
    flex: 0 0 400px !important;
  }

  .mySwiper .section.flexSection {
    flex: 0 0 0px !important;
  }

  .mySwiper .section .content .picture {
    width: 400px !important;
    height: 230px !important;
    margin: 0 auto !important;
  }

  .mySwiper .section .content .text {
    width: 400px !important;
  }

  .mySwiper .sectionStick .dot {
    width: 12px !important;
    height: 12px !important;
  }

  .mySwiper .sectionStick .dot::after {
    width: 3px !important;
    height: 3px !important;
  }

  .mySwiper .sectionsContainer .inactiveSection {
    transform: scale(0.8) !important;
    margin: 0 auto !important;
    opacity: 0.5 !important;
    transition: 0.4s ease !important;
  }

  .mySwiper .sectionsContainer .inactiveSection2 {
    transform: scale(0.6) !important;
  }
}

@media (max-width: 481px) {
  .mySwiper .section {
    flex: 0 0 0px !important;
  }

  .mySwiper .section .content .picture {
    width: 330px !important;
    height: 175px !important;
    margin: 0 auto !important;
  }

  .mySwiper .section .content .text {
    width: 330px !important;
    display: block !important;
    padding: 0 10px !important;
    margin-top: 20px !important;
  }

  .mySwiper .section .content .text .number p {
    text-align: start !important;
    margin-bottom: 20px !important;
  }

  .mySwiper .section .content .text .description {
    width: 310px !important;
  }

  .mySwiper .section .content .text .description h5 {
    margin-top: 0 !important;
    font-size: 30px !important;
    height: auto !important;
    line-height: 38px !important;
  }

  .mySwiper .section .content .text .description p {
    text-align: start !important;
    font-size: 16px !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical !important;
  }

  .mySwiper .section .content .text .description a {
    font-size: 16px !important;
    font-weight: 300 !important;
  }

  .mySwiper .sectionsContainer {
    align-items: start !important;
  }

  .mySwiper .sectionsContainer .inactiveSection {
    transform: scale(1) !important;
    margin: 0 auto !important;
    opacity: 0.5 !important;
    transition: 0.4s ease !important;
    align-items: start !important;
  }
}