.root {
  font-family: "Oxanium", sans-serif;
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 0px;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  &.ru {
    font-family: "Montserrat", sans-serif !important;
    ul{
        li{
            padding-top: 10px;
            font-size: 60px;
            a,div{
              .strikText{
                font-size: 60px;
              }
            }
        }
    }
  }
  .model {
    position: absolute;
    top: -15%;
    right: 0%;
    transform: translateY(40%);
    width: 500px;
    height: 110vh;
    z-index: 90;
    background-color: transparent;
  }
  div {
    text-align: start;
    p {
      margin-left: 20vw;
      color: white;
      font-weight: 500;
      font-size: 13px;
    }
    ul {
      padding: 0;
      text-align: start;
      li {
        padding-left: 20vw;
        list-style: none;
        font-weight: 700;
        font-size: 90px;
        line-height: 81px;
        border-top-width: 1px;
        border-top-style: solid;
        border-image: radial-gradient(
          circle,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 60, 58, 0) 100%
        );
        transition: 0.5s;
        position: relative;
        z-index: 5;
        a,
        div {
          width: max-content;
          color: white;
          text-decoration: none;
          transition: 0.3s;
          display: flex;
          gap: 10px;
          justify-content: start;
          z-index: 5;
          .strikText {
            font-weight: 300;
            font-size: 90px;
            color: rgba(255, 255, 255, 0.5);
          }
          .hover_text {
            display: none;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            width: 286px;
            color: black;
          }
          img {
            display: none;
          }
        }
        &:hover {
          background: rgb(255, 60, 58);
          background: linear-gradient(
            90deg,
            rgba(255, 60, 58, 1) 0%,
            rgba(243, 197, 127, 1) 20%,
            rgba(255, 60, 58, 1) 100%
          );
          a {
            color: black;
            display: flex;
            align-items: center;
            .strikText {
              display: none;
            }
            .hover_text {
              display: block;
              height: auto;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Количество строк, после которых текст будет обрезан */
              -webkit-box-orient: vertical;
              line-height: 1.2em; /* Установите высоту строки */
              max-height: 2.4em; /* 2 строки * высота строки */
              text-overflow: ellipsis;
            }
            img {
              display: block;
              color: black;
            }
          }
        }
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: " ";
          width: 100%;
          height: 1px;
          background: rgb(255, 255, 255);
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 60, 58, 0) 100%
          );
        }
      }
    }
  }
}

@media (min-width: 2100px) {
  .root, .ru {
    div {
      p {
        margin-left: 10vw;
      }
      ul {
        li {
          padding-left: 10vw;
        }
      }
    }
  }
}

@media (max-width: 1323px) {
  .root, .ru {
    div {
      p {
        margin-left: 15vw;
      }
      ul {
        li {
          padding-left: 15vw;
        }
      }
    }
  }
}
@media (max-width: 1237px) {
  .root, .ru {
    div {
      p {
        margin-left: 15vw;
      }
      ul {
        li {
          padding-left: 15vw;
          font-size: 60px;
          a {
            width: fit-content;
            .strikText {
              font-size: 60px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .root, .ru {
    .model {
      width: 500px;
      height: 70vh;
      top: 30%;
      bottom: 0;
    }
  }
}

@media (max-width: 840px) {
  .root, .ru {
    div {
      ul {
        li {
          padding-left: 15vw;
          font-size: 50px;
          // line-height: 60px;
          a {
            width: fit-content;
            .strikText {
              font-size: 50px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .root, .ru {
    text-align: start;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: radial-gradient(rgba(59, 31, 55, 0.5), rgba(19, 14, 33, 0.5));
      z-index: 2;
    }
    .model {
      z-index: 1;
      top: 25%;
      left: 50%;
      width: 100%;
      height: 100vh;
      transform: translateX(-50%);
      canvas {
        color: rgba(0, 0, 0, 0.434);
      }
    }
    div {
      text-align: left;
      ul {
        text-align: left;
        li {
          text-align: left;
          font-size: 40;
          font-weight: 500;
          padding-left: 15vw;
          line-height: 52px;
          a {
            width: 100%;

            text-align: left;
            .strikText {
              display: none;
            }
          }
          &:hover {
            a {
              .hover_text {
                display: none;
              }
              img {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 383px) {
  .root {
    div {
      ul {
        li {
          font-size: 32px;
          font-weight: 500;
          padding-left: 15vw;
          line-height: 52px;
        }
      }
    }
  }
}
